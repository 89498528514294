<template>
  <div class="sankeyContainer">
    <div ref="sankeyChart" style="width: 100%; height: 700px"></div>
    <b-modal
      ref="childNodeInfoModal"
      centered
      size="lg"
      @hidden="closeChildNodeInfoModal"
    >
      <template #modal-header>
        <h5>부하 구성 목록</h5>
      </template>
      <template #modal-footer>
        <div style="display: flex; flex: 1">
          <button
            type="button"
            class="btn btn-secondary"
            style="flex: 1"
            @click="closeChildNodeInfoModal"
          >
            닫기
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: ["data", "phscPointUsageMap"],
  data() {
    return {};
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    data() {
      this.renderChart();
    },
  },
  methods: {
    closeChildNodeInfoModal() {
      this.$refs.childNodeInfoModal.hide();
    },
    onNodeSelect() {
      this.$refs.childNodeInfoModal.show();
    },
    findReplaceablePoint(item) {
      const replacePoint = this.phscPointUsageMap.get(item);
      return replacePoint ? replacePoint : item;
    },
    renderChart() {
      let chart = echarts.init(this.$refs.sankeyChart);

      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          formatter: function (params) {
            if (params.dataType === "edge") {
              return `${this.findReplaceablePoint(
                params.data.source
              )} ▶ ${this.findReplaceablePoint(
                params.data.target
              )} <span style="font-weight: bold;"> ${Number(
                params.data.value
              ).toFixed(3)} ${params.data.unit}</span>`;
            } else if (params.dataType === "node") {
              const inputs = this.data.links
                .filter((link) => link.target === params.name)
                .map(
                  (link) =>
                    `${this.findReplaceablePoint(
                      link.source
                    )} ▶ ${this.findReplaceablePoint(link.target)}: ${Number(
                      link.value
                    ).toFixed(3)} ${link.unit || "kWh"}`
                );

              const outputs = this.data.links
                .filter((link) => link.source === params.name)
                .map(
                  (link) =>
                    `${this.findReplaceablePoint(
                      link.source
                    )} ▶ ${this.findReplaceablePoint(link.target)}: ${Number(
                      link.value
                    ).toFixed(3)} ${link.unit || "kWh"}`
                );

              const inputText = inputs.length
                ? `<br/><b>Inputs</b><br/>${inputs.join("<br/>")}`
                : "";
              const outputText = outputs.length
                ? `<br/><b>Outputs</b><br/>${outputs.join("<br/>")}`
                : "";

              return `
                            ${this.findReplaceablePoint(params.name)}<br/>
                            <span style="font-weight: bold;">${Number(
                              params.value
                            ).toFixed(3)} kWh</span>
                            <br/>
                            <small>
                            ${inputText}
                            ${outputText}
                            </small>`;
            }
          }.bind(this),
        },
        series: [
          {
            type: "sankey",
            data: this.data.data,
            links: this.data.links,
            emphasis: {
              focus: "adjacency",
            },
            draggable: true,
            left: "center",
            top: "middle",
            layout: "none",
            width: "85%",
            height: "95%",
            label: {
              show: true,
              formatter: function (params) {
                const value = Number(params.value);
                if (value != 0)
                  return `{name|${this.findReplaceablePoint(
                    params.name
                  )}}\n{value|${Number(params.value).toFixed(3)} kWh}`;
                else return "";
              }.bind(this),
              rich: {
                name: {
                  fontWeight: "bold",
                  color: "#444",
                  fontSize: 12,
                  lineHeight: 20,
                },
                value: {
                  fontWeight: 500,
                  color: "#888",
                  fontSize: 12,
                },
              },
            },
            lineStyle: {
              color: "gradient",
              curveness: 0.1,
            },
          },
        ],
      };

      chart.setOption(option);

      chart.on("click", (params) => {
        if (params.dataType === "node") {
          this.onNodeSelect(params);
        }
      });

      window.addEventListener("resize", () => {
        chart.resize();
      });
    },
  },
};
</script>

<style scoped>
.sankeyContainer {
  margin-top: 50px;
}
</style>
