<template>
  <div
    class="doughnut-chart"
    ref="doughnutChart"
    style="
      border: 1px solid #ececec;
      border-radius: 10px;
      box-shadow: 3px 3px 3px #ececec;
    "
  />
</template>

<script>
import * as echarts from "echarts";
import xelib from "xelib";

export default {
  props: ["data"],
  data() {
    return {
      chartData: [],
      convertData: null,
      option: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.name} : ${params.data.valueForTooltip.toFixed(
              2
            )} ${params.data.unitForTooltip} (${params.percent}%)`;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            avoidLabelOverlap: false,
            padAngle: 2,
            itemStyle: {
              borderRadius: 4,
            },
            center: ["50%", "50%"],
            data: [],
            label: {
              show: true,
              formatter: function (params) {
                const regex = /\/(.+)\//;
                const match = regex.exec(params.name);
                return `{b|${match ? match[1] : params.name}}\n{d|${
                  params.percent
                }%}`;
              },
              position: "outside",
              rich: {
                b: {
                  fontSize: 14,
                  color: "#333",
                  lineHeight: 40,
                  align: "center",
                },
                d: {
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#666",
                  align: "center",
                },
              },
            },
            labelLine: {
              smooth: 0.2,
              length: 10,
              length2: 20,
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: () => Math.random() * 200,
          },
        ],
      },
    };
  },
  methods: {
    //정수인지아닌지를 판별하여 정수가 아닐 시 소수점 두번째자리까지 자릅니다.
    customToFixed(val) {
      let copyVal = val > 0 ? val : -val;
      return Number.isInteger(copyVal) ? copyVal : copyVal.toFixed(2);
    },
    //단위가 kWh가 아닐 시 변환하는 함수입니다.
    modifyData(data) {
      const { Unit } = xelib.PointBoxV2;
      const tempObj = JSON.parse(JSON.stringify(data));
      this.convertData = tempObj.map((item) => ({
        ...item,
        unit: "kWh",
        value:
          item.unit !== "kWh"
            ? this.customToFixed(Unit.convert(item.value, item.unit, "kWh"))
            : this.customToFixed(item.value),
        valueForTooltip: item.value,
        unitForTooltip: item.unit,
      }));
    },
    renderChart() {
      const chart = echarts.init(this.$refs.doughnutChart);
      this.option.series.data = this.convertData;
      chart.setOption(this.option);
      window.addEventListener("resize", () => {
        chart.resize();
      });
    },
  },
  mounted() {
    this.renderChart();
  },
  created() {
    this.modifyData(this.data);
    this.option.series.data = this.convertData;
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.modifyData(newData);
        this.option.series[0].data = this.convertData;
        if (this.$refs.doughnutChart) {
          this.renderChart();
        }
      },
    },
  },
};
</script>

<style scoped>
.doughnut-chart {
  height: 100%;
  width: 100%;
}
</style>
