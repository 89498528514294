<template>
    <div>
        <!-- @node-select="onNodeSelect" @node-unselect="onNodeUnselect" @node-collapse="onNodeCollapse" @node-expand="onNodeExpand" -->
        <org-chart
            :value="nodeData"
            :collapsible="true"
            class="node-org-chart"
            selectionMode="single"
            @node-select="onNodeSelect"
        >
            <template #default="slotProps">
                <div v-if="slotProps.node.data.nodeType !== 'Point'" class="row m-0" style="height: 100%; width: 100%">
                    <div class="col-3 p-0" style="height: 100%; width: 100%; padding-right: 0.5rem !important">
                        <div class="progress progress-bar-vertical">
                            <div
                                class="progress-bar"
                                role="progressbar"
                                :aria-valuenow="slotProps.node.data.sumRatio"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="{
                                    height: Math.abs(slotProps.node.data.sumRatio) + '%',
                                    background:
                                        slotProps.node.data.sumRatio < 0
                                            ? 'linear-gradient(#90CAF9, #BBDEFB, #E3F2FD)'
                                            : 'linear-gradient(#E57373, #EF9A9A, #FFCDD2)',
                                }"
                            ></div>
                        </div>
                        <div>
                            <span class="m-0" style="font-weight: 700">
                                {{ `${slotProps.node.data.sumRatio.toFixed(0) ?? "-"} %` }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="col-9 p-0 d-flex justify-content-around flex-column"
                        style="height: 100%; padding-left: 0.5rem !important; border-left: 1px solid #cfd8dc;"
                    >
                        <div class="d-flex align-items-center justify-content-center">
                            <span class="m-0">
                                {{ `${slotProps.node.data.text ?? "-"} (${slotProps.node.data.sysNodeIdx ?? "-"})` }}
                            </span>
                        </div>
                        <div>
                            <span class="m-0" style="font-weight: bold; font-size: 14px !important">
                                {{ formatValue(Number(slotProps.node.data.sumVal)) ?? "-" }}
                            </span>
                            <span class="m-0">{{ ` ${slotProps.node.data.sumUnit ?? "-"}` }}</span>
                        </div>
                        <div v-if="slotProps.node.data.sumItems && valueDpType == 'percent'">
                            <span v-for="(key, index) in Object.keys(slotProps.node.data.sumItems)" :key="index">
                                {{
                                    `${
                                        $store.state.unitTypes.find((type) => type.value == key)
                                            ? $store.state.unitTypes.find((type) => type.value == key).text
                                            : "-"
                                    } :
                                ${
                                    slotProps.node.data.sumItems[key].sumVal
                                        ? percentValue(
                                              slotProps.node.data.sumVal,
                                              slotProps.node.data.sumItems[key].sumVal,
                                              slotProps.node.data.sumItems[key].unit,
                                              slotProps.node.data.sumUnit
                                          )
                                        : "-"
                                } %`
                                }}
                            </span>
                        </div>
                        <div v-else-if="slotProps.node.data.sumItems && valueDpType == 'format'">
                            <span v-for="(key, index) in Object.keys(slotProps.node.data.sumItems)" :key="index">
                                {{
                                    `${
                                        $store.state.unitTypes.find((type) => type.value == key)
                                            ? $store.state.unitTypes.find((type) => type.value == key).text
                                            : "-"
                                    } :
                                ${
                                    slotProps.node.data.sumItems[key].sumVal
                                        ? convertValue(
                                              slotProps.node.data.sumItems[key].sumVal,
                                              slotProps.node.data.sumItems[key].unit,
                                              slotProps.node.data.sumUnit
                                          )
                                        : "-"
                                }
                                ${slotProps.node.data.sumUnit}`
                                }}
                            </span>
                        </div>
                        <div v-else-if="slotProps.node.data.sumItems && valueDpType == 'origin'">
                            <span v-for="(key, index) in Object.keys(slotProps.node.data.sumItems)" :key="index">
                                {{
                                    `${
                                        $store.state.unitTypes.find((type) => type.value == key)
                                            ? $store.state.unitTypes.find((type) => type.value == key).text
                                            : "-"
                                    } :
                                ${
                                    slotProps.node.data.sumItems[key].sumVal
                                        ? formatValue(slotProps.node.data.sumItems[key].sumVal)
                                        : "-"
                                }
                                ${
                                    slotProps.node.data.sumItems[key].unit
                                        ? slotProps.node.data.sumItems[key].unit
                                        : "-"
                                }`
                                }}
                            </span>
                        </div>
                        <div v-else>
                            <span>-</span>
                        </div>
                        <div>
                            <div class="org-button-ctn">
                                <button
                                    v-b-tooltip.hover
                                    :title="$t('관제점 상세')"
                                    class="org-button btn-pt-border"
                                    type="button"
                                    :disabled="!slotProps.node.data.savePtIdx"
                                    :style="{
                                        backgroundColor: !slotProps.node.data.savePtIdx ? '#CFD8DC' : '',
                                        color: !slotProps.node.data.savePtIdx ? '#B0BEC5' : '',
                                    }"
                                    @click="openPtDetail(slotProps.node.data.savePtIdx, $event)"
                                >
                                    SAVE
                                </button>
                                <button
                                    v-b-tooltip.hover
                                    :title="$t('설비 상세')"
                                    class="org-button"
                                    type="button"
                                    style="border: 0px !important"
                                    :disabled="!slotProps.node.data.equipIdx"
                                    :style="{
                                        backgroundColor: !slotProps.node.data.equipIdx ? '#CFD8DC' : '',
                                        color: !slotProps.node.data.equipIdx ? '#B0BEC5' : '',
                                    }"
                                    @click="
                                        openEqDetail(
                                            slotProps.node.data.equipIdx,
                                            slotProps.node.data.equipType,
                                            $event
                                        )
                                    "
                                >
                                    EP
                                </button>
                                <button
                                    v-b-tooltip.hover
                                    :title="
                                        $t(
                                            `${
                                                slotProps.node.data.sumEnabled == 'Y'
                                                    ? '계통 집계 활성화 상태'
                                                    : '계통 집계 비활성화 상태'
                                            }`
                                        )
                                    "
                                    class="org-button btn-sum-border"
                                    type="button"
                                    style="cursor: default !important"
                                    :style="{
                                        backgroundColor: slotProps.node.data.sumEnabled == 'Y' ? '#90A4AE' : '#CFD8DC',
                                        color: slotProps.node.data.sumEnabled == 'N' ? '#B0BEC5' : '',
                                    }"
                                >
                                    SUM
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row m-0" style="height: 100%; width: 100%">
                    <div class="col-3 p-0" style="height: 100%; width: 100%; padding-right: 0.5rem !important">
                        <div class="progress progress-bar-vertical">
                            <div
                                class="progress-bar"
                                role="progressbar"
                                :aria-valuenow="slotProps.node.data.ptRatio"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="{
                                    height: Math.abs(slotProps.node.data.ptRatio) + '%',
                                    background:
                                        slotProps.node.data.ptVal < 0
                                            ? 'linear-gradient(#E57373, #EF9A9A, #FFCDD2)'
                                            : 'linear-gradient(#90CAF9, #BBDEFB, #E3F2FD)',
                                }"
                            ></div>
                        </div>
                        <div>
                            <span class="m-0" style="font-weight: 700">
                                {{ `${slotProps.node.data.ptRatio ?? "-"} %` }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="col-9 p-0 d-flex justify-content-around flex-column"
                        style="
              height: 100%;
              padding-left: 0.5rem !important;
              border-left: 1px solid #cfd8dc;
            "
                    >
                        <div class="d-flex align-items-center justify-content-center">
                            <span class="m-0">
                                {{ `${slotProps.node.data.text ?? "-"} (${slotProps.node.data.ptIdx ?? "-"})` }}
                            </span>
                        </div>
                        <div>
                            <span class="m-0" style="font-weight: bold; font-size: 14px !important">
                                {{ formatValue(Number(slotProps.node.data.ptVal)) ?? "-" }}
                            </span>
                            <span class="m-0">{{ ` ${slotProps.node.data.unit ?? "-"}` }}</span>
                        </div>
                    </div>
                    <!-- {{ slotProps.node.data }} -->
                </div>
            </template>
        </org-chart>

        <b-modal v-if="ptIdx" ref="orgPtDetailModal" hide-footer centered size="lg" body-class="org-pt-modal-body">
            <template #modal-header>
                <h5>관제점 상세</h5>
            </template>
            <point-detail
                ref="point-detail"
                :title="''"
                :ptIdx="ptIdx"
                :noWatch="true"
                :global="true"
                :customHeight="'65vh'"
                @closed="handleClosed"
            />
        </b-modal>

        <b-modal
            v-if="equipIdx"
            ref="orgEqDetailModal"
            class="orgEqDetailModal"
            centered
            size="lg"
            body-class="org-eq-modal-body"
        >
            <template #modal-header>
                <h5>설비 상세</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button type="button" class="btn btn-secondary" style="flex: 1" @click="closeEqDetailModal">
                        닫기
                    </button>
                </div>
            </template>
            <EquipInfoDetail
                ref="EquipInfoDetail"
                :title="''"
                :equipType="equipType"
                :equipIdx="equipIdx"
                :type="'global'"
            />
        </b-modal>
        <b-modal v-if="ptSysNodeIdx" ref="ptMapsListModal" centered size="lg">
            <template #modal-header>
                <h5>관제점 목록</h5>
            </template>
            <PtMapsListPanel ref="PtMapsListPanel" :main="mainNode" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button type="button" class="btn btn-secondary" style="flex: 1" @click="closePtMapsListModal">
                        닫기
                    </button>
                </div>
            </template>
        </b-modal>
        <b-modal v-if="childNodeInfo" ref="childNodeInfoModal" centered size="lg" @hidden="closeChildNodeInfoModal">
            <template #modal-header>
                <h5>부하 구성 목록</h5>
                <div class="buttonGroup">
                    <button
                        class="status-icon"
                        :class="{ active: activeIcon === 'list' }"
                        v-b-tooltip.hover
                        :title="$t('목록 보기')"
                        @click="handleIconClick('list')"
                    >
                        <i class="fas fa-list-ul" aria-hidden="true"></i>
                    </button>
                    <button
                        class="status-icon"
                        :class="{ active: activeIcon === 'chart' }"
                        v-b-tooltip.hover
                        :title="$t('차트 보기')"
                        @click="handleIconClick('chart')"
                    >
                        <i class="fas fa-chart-area" aria-hidden="true"></i>
                    </button>
                </div>
            </template>
            <div v-if="activeIcon === 'list'">
                <ChildNodeInfoPanel ref="ChildNodeInfoPanel" :data="childNodeInfo" :main="mainNode" />
            </div>
            <div v-else-if="activeIcon === 'chart'">
                <facility-energy-bar-panel :data="childNodeInfo"></facility-energy-bar-panel>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button type="button" class="btn btn-secondary" style="flex: 1" @click="closeChildNodeInfoModal">
                        닫기
                    </button>
                </div>
            </template>
        </b-modal>
        <b-sidebar
            v-model="isSideBarShow"
            ref="ptInfoDetail"
            id="ptInfoDetail"
            bg-variant="light"
            backdrop-variant="secondary"
            @hidden="closePointDetail"
            width="33vw"
            no-header
            shadow
            right
            backdrop
        >
            <div v-if="isPointDetail" style="height: 100%">
                <PointInfoDetail
                    ref="point-info-detail"
                    :title="''"
                    :ptIdx="ptDetailIdx"
                    :noWatch="true"
                    @closed="closePointDetail"
                />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";
import PointDetail from "@src/views/new/point-management/point-mgmt/PointDetail.vue";
import EquipInfoDetail from "@src/views/new/management-info/equip-info/EquipInfoDetail.vue";
import PtMapsListPanel from "./PtMapsListPanel.vue";
import ChildNodeInfoPanel from "./ChildNodeInfoPanel.vue";
import PointInfoDetail from "@src/views/new/point-management/point-mgmt/PointDetail.vue";
import FacilityEnergyBarPanel from "./FacilityEnergyBarPanel.vue";
import xelib from "xelib";

const { Unit } = xelib.PointBoxV2;

export default {
    props: ["data", "queryType", "statusDate"],
    data() {
        return {
            nodeData: null,
            ptIdx: null,
            equipIdx: null,
            // ptEquipIdx: null,
            equipType: null,
            mainNode: null,
            childNodeInfo: null,
            valueDpType: "percent",
            ptSysNodeIdx: null,
            isSideBarShow: false,
            isPointDetail: false,
            ptDetailIdx: null,
            activeIcon: "list",
        };
    },
    components: {
        PointDetail,
        EquipInfoDetail,
        PtMapsListPanel,
        ChildNodeInfoPanel,
        PointInfoDetail,
        FacilityEnergyBarPanel,
    },
    computed: {},
    watch: {
        // props 로 받는 data 변수 값이 바뀔 경우 데이터 세팅 함수 호출 (Rerender)
        data() {
            this.getData();
        },
    },
    async created() {
        await this.getData();
    },
    mounted() {
        this.$nextTick(() => {
            const orgChartScroll = document.querySelector(".opStatus");
            if (!orgChartScroll) return;
            else this.addScrollEventListeners(orgChartScroll);
        });
    },
    destroyed() {
        const orgChartScroll = document.querySelector(".opStatus");
        if (orgChartScroll) this.removeScrollEventListeners(orgChartScroll);
    },
    methods: {
        handleIconClick(icon) {
            this.activeIcon = icon;
        },
        async getData() {
            const {
                sysNodeIdx,
                text,
                nodeType,
                children,
                sysType,
                equipType,
                sumVal,
                sumUnit,
                sumRatio,
                savePtIdx,
                sumEnabled,
                equipIdx,
                sumItems,
            } = this.data[0];
            this.nodeData = {
                key: sysNodeIdx,
                type: nodeType,
                styleClass: "org-container",
                data: {
                    nodeType: nodeType ?? null,
                    text: text ?? null,
                    sysNodeIdx: sysNodeIdx ?? null,
                    sysType: sysType ?? null,
                    equipType: equipType ?? null,
                    sumVal: this.formatValue(sumVal) ?? null,
                    sumUnit: sumUnit ?? null,
                    sumRatio: sumRatio ?? null,
                    savePtIdx: savePtIdx ?? null,
                    sumEnabled: sumEnabled ?? null,
                    equipIdx: equipIdx ?? null,
                    sumItems: sumItems,
                },
                children: [],
            };
            if (this.data[0].nodeType !== "Equip") {
                let child = await this.getCustomOrgChartData(children);
                this.nodeData.children = child;
            } else {
                this.data[0].children.forEach((child) => {
                    let item = {
                        key: child.ptIdx,
                        type: child.nodeType,
                        styleClass: "org-container-point",
                        data: {
                            nodeType: child.nodeType ?? null,
                            ptIdx: child.ptIdx ?? null,
                            text: child.text ?? null,
                            ptVal: child.ptVal ?? null,
                            ptRatio: child.ptRatio ?? null,
                            ptReltnType: child.ptReltnType ?? null,
                            ptType: child.ptType ?? null,
                            unitType: child.unitType ?? null,
                            unit: child.unit ?? null,
                        },
                    };
                    this.nodeData.children.push(item);
                });
            }
        },

        getCustomOrgChartData(data) {
            return data.map((item) => {
                const {
                    sysNodeIdx,
                    text,
                    nodeType,
                    children,
                    sysType,
                    equipType,
                    sumVal,
                    sumUnit,
                    sumRatio,
                    savePtIdx,
                    sumEnabled,
                    equipIdx,
                    sumItems,
                } = item;
                return {
                    key: sysNodeIdx,
                    type: nodeType,
                    styleClass: "org-container",
                    data: {
                        nodeType: nodeType ?? null,
                        text: text ?? null,
                        sysNodeIdx: sysNodeIdx,
                        sysType: sysType ?? null,
                        equipType: equipType ?? null,
                        sumVal: sumVal ?? null,
                        sumUnit: sumUnit ?? null,
                        sumRatio: sumRatio ?? null,
                        savePtIdx: savePtIdx ?? null,
                        sumEnabled: sumEnabled ?? null,
                        equipIdx: equipIdx ?? null,
                        sumItems: sumItems,
                    },
                    children: children ? this.getCustomOrgChartData(children) : [],
                };
            });
        },
        addScrollEventListeners(scrollContainer) {
            this.scrollContainer = scrollContainer;
            this.isDown = false;
            this.startX = 0;
            this.scrollLeft = 0;

            this.handleMouseDown = (e) => {
                this.isDown = true;
                scrollContainer.classList.add("active");
                this.startX = e.pageX - scrollContainer.offsetLeft;
                this.scrollLeft = scrollContainer.scrollLeft;
            };

            this.handleMouseLeave = () => {
                this.isDown = false;
                scrollContainer.classList.remove("active");
            };

            this.handleMouseUp = () => {
                this.isDown = false;
                scrollContainer.classList.remove("active");
            };

            this.handleMouseMove = (e) => {
                if (!this.isDown) return;
                e.stopPropagation();
                const x = e.pageX - scrollContainer.offsetLeft;
                const walk = (x - this.startX) * 2;
                scrollContainer.scrollLeft = this.scrollLeft - walk;
            };

            scrollContainer.addEventListener("mousedown", this.handleMouseDown);
            scrollContainer.addEventListener("mouseleave", this.handleMouseLeave);
            scrollContainer.addEventListener("mouseup", this.handleMouseUp);
            scrollContainer.addEventListener("mousemove", this.handleMouseMove);
        },
        removeScrollEventListeners(scrollContainer) {
            scrollContainer.removeEventListener("mousedown", this.handleMouseDown);
            scrollContainer.removeEventListener("mouseleave", this.handleMouseLeave);
            scrollContainer.removeEventListener("mouseup", this.handleMouseUp);
            scrollContainer.removeEventListener("mousemove", this.handleMouseMove);
        },
        openPtDetail(ptIdx, event) {
            event.stopPropagation();
            this.ptIdx = ptIdx;
            this.$nextTick(() => {
                this.$refs.orgPtDetailModal.show();
            });
        },
        async handleClosed(ptIdx) {
            console.log(ptIdx);
            this.$refs.orgPtDetailModal.hide();
        },
        openEqDetail(equipIdx, equipType, event) {
            event.stopPropagation();
            this.equipIdx = equipIdx;
            this.equipType = equipType;
            this.$nextTick(async () => {
                await this.$refs.orgEqDetailModal.show();
                await this.$refs.EquipInfoDetail.loadData();
            });
        },
        closeEqDetailModal() {
            this.$refs.orgEqDetailModal.hide();
        },
        findNodeBySysNodeIdx(data, targetSysNodeIdx) {
            // 현재 노드의 sysNodeIdx가 targetSysNodeIdx와 일치하는지 확인
            if (data.sysNodeIdx === targetSysNodeIdx) {
                return data;
            }

            // 현재 노드에 children이 있다면, 각 child를 재귀적으로 탐색
            if (data.children) {
                for (let child of data.children) {
                    let result = this.findNodeBySysNodeIdx(child, targetSysNodeIdx);
                    if (result) {
                        return result;
                    }
                }
            }

            // 일치하는 노드를 찾지 못한 경우 null 반환
            return null;
        },
        onNodeSelect(node) {
            if (node.data.nodeType == "Root" || node.data.nodeType == "System" || node.data.nodeType == "Group") {
                if (node.data.sysNodeIdx == this.data[0].sysNodeIdx) {
                    this.mainNode = {
                        sysNodeIdx: this.data[0].sysNodeIdx,
                        nodeName: this.data[0].text,
                        sumVal: this.data[0].sumVal,
                        unit: this.data[0].sumUnit,
                    };
                    this.childNodeInfo = this.data[0].children;
                } else {
                    let find = this.findNodeBySysNodeIdx(this.data[0], node.data.sysNodeIdx);
                    if (find && find.children) {
                        this.mainNode = {
                            sysNodeIdx: find.sysNodeIdx,
                            nodeName: find.text,
                            sumVal: find.sumVal,
                            unit: find.sumUnit,
                        };
                        this.childNodeInfo = find.children;
                    }
                }
                this.$nextTick(() => {
                    this.$refs.childNodeInfoModal.show();
                });
            } else if (node.data.nodeType == "Equip") {
                this.mainNode = {
                    sysNodeIdx: node.data.sysNodeIdx,
                    nodeName: node.data.text,
                    sumVal: node.data.sumVal,
                    unit: node.data.sumUnit,
                };
                // this.ptEquipIdx = node.data.equipIdx;
                this.ptSysNodeIdx = node.data.sysNodeIdx;
                this.$nextTick(async () => {
                    await this.$refs.ptMapsListModal.show();
                    await this.$refs.PtMapsListPanel.getPtMapList(this.ptSysNodeIdx, this.queryType, this.statusDate);
                });
            } else {
                this.showPointDetail(node.data.ptIdx);
            }
        },
        async closePtMapsListModal() {
            await this.$refs.ptMapsListModal.hide();
            // this.mainNode = null;
        },
        async closeChildNodeInfoModal() {
            await this.$refs.childNodeInfoModal.hide();
            this.mainNode = null;
            this.childNodeInfo = null;
            this.activeIcon = "list";
        },
        formatValue(value) {
            if (Number.isInteger(value)) {
                return value;
            } else {
                return value.toFixed(2);
            }
        },
        async formatData(type) {
            this.valueDpType = type;
        },
        percentValue(totalValue, value, rowUnit, convertUnit) {
            let convert = Unit.convert(value, rowUnit, convertUnit);
            return ((Number(convert) / totalValue) * 100).toFixed(0);
        },
        convertValue(value, rowUnit, mainUnit) {
            let val;
            if (rowUnit !== mainUnit) {
                val = Unit.convert(value, rowUnit, mainUnit);
            } else val = value;
            return Number.isInteger(val) ? val : val.toFixed(2);
        },
        closePointDetail() {
            this.$refs.ptInfoDetail.hide();
            this.isSideBarShow = false;
            this.isPointDetail = false;
        },
        async showPointDetail(ptIdx) {
            if (ptIdx) {
                this.ptDetailIdx = ptIdx;
                this.isSideBarShow = true;
                this.isPointDetail = true;
                // if(event) await event.stopPropagation();
            } else return;
        },
    },
};
</script>

<style scoped>
.buttonGroup {
    display: flex;
}

.org-button-ctn {
    display: flex;
    flex: 1;
}

.org-button {
    background-color: #90a4ae;
    color: white;
    flex: 1;
    height: 1.45rem;
}

.org-button:hover {
    background-color: #546e7a;
}

.btn-pt-border {
    border-top: 0px !important;
    border-right: 1px solid #b0bec5;
    border-bottom: 0px !important;
    border-left: 0px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.btn-sum-border {
    border-top: 0px !important;
    border-right: 0px !important;
    border-bottom: 0px !important;
    border-left: 1px solid #b0bec5;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}

.status-icon.active {
    color: #fff;
    background-color: #555;
}
</style>
