<template>
  <div class="gauge-chart" ref="sumDoughnutChart" />
</template>

<style scoped>
.gauge-chart {
  height: 100%;
  width: 100%;
}
</style>

<script>
import * as echarts from "echarts";
export default {
  props: ["data"],
  data() {
    return {};
  },
  mounted() {
    this.renderChart();
    // this.$nextTick(() => {
    //     window.addEventListener('resize', this.resizeCharts);
    // });
  },
  watch: {
    data() {
      this.renderChart();
    },
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeCharts);
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.sumDoughnutChart);
      chart.setOption(this.data);
    },
    // resizeCharts() {
    //     this.$refs.sumDoughnutChart.forEach(chart => chart.resize());
    // }
  },
};
</script>