const DangerColor = "#D50000";
const WarningColor = "#FFAB00";
const NormalColor = "#4DD0E1";
const AbnormalColor = "#ECEFF1";

export default {
    setDataForProPerty(data) {
        let max;
        let min = 0;
        let splitNumber = 10;
        let color;
        let chartData;
        if (data.propCode.includes("TEMP")) {
            max = 40;
            color = [
                [15 / 40, AbnormalColor],
                [19 / 40, WarningColor],
                [25 / 40, NormalColor],
                [30 / 40, WarningColor],
                [40 / 40, DangerColor],
            ];
            chartData = [
                {
                    value: Number(data.sumVal ? data.sumVal.toFixed(0) : data.ptVal ? data.ptVal.toFixed(0) : 0),
                    name: data.propName,
                },
            ];
        } else if (data.propCode.includes("HUMI")) {
            max = 100;
            color = [
                [2 / 10, DangerColor],
                [4 / 10, WarningColor],
                [6 / 10, NormalColor],
                [8 / 10, WarningColor],
                [10 / 10, DangerColor],
            ];
            chartData = [
                {
                    value: Number(data.sumVal ? data.sumVal.toFixed(0) : data.ptVal ? data.ptVal.toFixed(0) : 0),
                    name: data.propName,
                },
            ];
        } else if (data.propCode.includes("DI")) {
            max = 100;
            color = [
                [50 / 100, AbnormalColor],
                [70 / 100, NormalColor],
                [80 / 100, WarningColor],
                [100 / 100, DangerColor],
            ];
            chartData = [
                {
                    value: Number(data.sumVal ? data.sumVal.toFixed(0) : data.ptVal ? data.ptVal.toFixed(0) : 0),
                    name: data.propName,
                },
            ];
        } else if (data.propCode.includes("PMV")) {
            max = 3;
            min = -3;
            splitNumber = 12;
            color = [
                [1 / 6, DangerColor],
                [2 / 6, WarningColor],
                [3 / 6, NormalColor],
                [4 / 6, NormalColor],
                [5 / 6, WarningColor],
                [6 / 6, DangerColor],
            ];
            chartData = [
                {
                    value: Number(data.sumVal ? data.sumVal.toFixed(1) : data.ptVal ? data.ptVal.toFixed(1) : 0),
                    name: data.propName,
                },
            ];
        } else {
            max = 100;
        }
        return { max, min, splitNumber, color, chartData };
    },
    setSummaryGuageData(baseData, units) {
        return baseData.map((item) => {
            let chartOption = this.setDataForProPerty(item);
            const { max, min, splitNumber, color, chartData } = chartOption;

            return {
                series: [
                    {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        center: ["50%", "70%"],
                        radius: "90%",
                        min: min,
                        max: max,
                        splitNumber: splitNumber,

                        axisLine: {
                            lineStyle: {
                                width: 8,
                                color: color,
                            },
                        },
                        pointer: {
                            icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                            length: "30%",
                            width: 12,
                            offsetCenter: [0, "-60%"],
                            itemStyle: {
                                color: "#FF0000",
                            },
                        },
                        axisTick: {
                            length: 4,
                            distance: 1,
                            lineStyle: {
                                color: "#90A4AE",
                                width: 1,
                            },
                        },
                        splitLine: {
                            length: 8,
                            distance: 1,
                            lineStyle: {
                                color: "#90A4AE",
                                width: 2,
                            },
                        },
                        axisLabel: {
                            color: "#464646",
                            fontSize: 10,
                            distance: -20,
                            rotate: "tangential",
                        },
                        title: {
                            offsetCenter: [0, "-10%"],
                            fontSize: 14,
                        },
                        detail: {
                            fontSize: 16,
                            offsetCenter: [0, "-30%"],
                            valueAnimation: true,
                            color: "black",
                            formatter: function(value) {
                                return `${value} ${
                                    item.unit !== "-" ? units.find((i) => i.value == item.unit).text : ""
                                }`;
                            },
                        },
                        data: chartData,
                    },
                ],
            };
        });
    },
};
