<template>
  <div class="row" style="height: 100%">
    <div
      v-if="baseData"
      class="col-9"
      ref="lineChart"
      style="width: 100%; height: 100%"
    ></div>

    <div v-if="statsDesc" class="col-3">
      <!-- 분석지표 / 분포도 / 이상치 -->
      <div class="row m-0">
        <button
          class="col status-icon"
          :class="{ active: activeIcon === 'statsDesc' }"
          @click="handleIconClick('statsDesc')"
          :title="'분석지표'"
        >
          <span>분석지표</span>
        </button>
        <button
          class="col status-icon"
          :class="{ active: activeIcon === 'dataDist' }"
          @click="handleIconClick('dataDist')"
          :title="'분포도'"
        >
          <span>분포도</span>
        </button>
        <button
          class="col status-icon"
          :class="{ active: activeIcon === 'outliers' }"
          @click="handleIconClick('outliers')"
          :title="'이상 데이터'"
        >
          <span>이상치</span>
        </button>
      </div>
      <div v-if="activeIcon === 'statsDesc'" class="row m-0">
        <div class="table-container">
          <b-table-simple bordered class="text-center">
            <b-thead>
              <b-tr>
                <b-th>지표</b-th>
                <b-th>지표값</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>최소값</b-th>
                <b-td>{{ this.baseData.statsDesc.min || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>최대값</b-th>
                <b-td>{{ this.baseData.statsDesc.max || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>평균값</b-th>
                <b-td>{{ this.baseData.statsDesc.mean || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>표준편차</b-th>
                <b-td>{{ this.baseData.statsDesc.stdDev || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>1사분위수(Q1)</b-th>
                <b-td>{{ this.baseData.statsDesc.quartile.Q1 || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>2사분위수(Q2)</b-th>
                <b-td>{{ this.baseData.statsDesc.quartile.Q2 || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>3사분위수(Q3)</b-th>
                <b-td>{{ this.baseData.statsDesc.quartile.Q3 || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>분산</b-th>
                <b-td>{{ this.baseData.statsDesc.variance || 0 }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>

      <div v-if="activeIcon === 'dataDist'" class="row m-0">
        <div ref="analyzeBarChart" class="chart"></div>
      </div>

      <div v-if="activeIcon === 'outliers'" class="row m-0">
        <div class="table-container">
          <b-table-simple bordered class="text-center">
            <b-thead>
              <b-tr>
                <b-th>결정지표</b-th>
                <b-th>지표값</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>IQR</b-th>
                <b-td>{{ this.baseData.statsDesc.IQR || 0 }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>이상값 상한</b-th>
                <b-td>{{
                  this.baseData.statsDesc.abnormalRange.upper || 0
                }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>이상값 하한</b-th>
                <b-td>{{
                  this.baseData.statsDesc.abnormalRange.lower || 0
                }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="weird-table-container">
          <b-table-simple bordered class="text-center align-middle">
            <b-thead>
              <b-tr>
                <b-th>이상 위치</b-th>
                <b-th>이상값</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) in this.baseData.statsDesc.outliers"
                :key="index"
              >
                <b-th>{{ item.regDt.replace("T", " ") }}</b-th>
                <b-td>{{ item.ptVal }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as echarts from "echarts";

const primaryColor = "#FF5722";
const secondaryColor = "#B0BEC5";

function formatNumber(num) {
  let val = num.toLocaleString("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  return val;
}

export default {
  data() {
    return {
      activeIcon: "statsDesc",

      xAxisLabel: null,
      baseDataName: "",
      baseDataset: [],
      compDataName: "",
      compDataset: [],
      unitMap: {},
      predictDataSet: [],
      predictDataName: "",

      statsDesc: null,
      analyzeBarChart: null,
    };
  },
  props: [
    "baseData",
    "compData",
    "dispBaseIdx",
    "dispCompIdx",
    "queryType",
    "predictData",
    "predictTitle",
    "unit",
  ],
  created() {},
  mounted() {
    if (!this.isEmpty(this.compData)) {
      this.prepareChartData();
      this.$nextTick(() => {
        if (this.$refs.analyzeBarChart) {
          this.initCharts();
        }
      });
    }
  },

  computed: {},
  watch: {
    compData() {
      this.prepareChartData();
    },
    predictData() {
      this.prepareChartData();
    },
    baseData() {
      this.prepareChartData();
    },
    dispCompIdx() {
      this.prepareChartData();
    },
    activeIcon(value) {
      if (value === "dataDist") {
        this.initCharts();
      } else {
        this.analyzeBarChart && this.analyzeBarChart.dispose();
        this.analyzeBarChart = null;
      }
    },
    statsDesc() {
      this.statsDesc && this.initCharts();
    },
  },
  methods: {
    initCharts() {
      this.$nextTick(() => {
        this.analyzeBarChart =
          this.$refs.analyzeBarChart &&
          echarts.init(this.$refs.analyzeBarChart);
        const option = {
          xAxis: {
            type: "category",
            data: this.statsDesc.frequences.range.map(
              (item) => `${item[0] || 0}~${item[1] || 0}`
            ),
            axisLabel: {
              rotate: 45,
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: Object.entries(this.statsDesc.frequences.data).map(
                ([, value]) => value
              ),
              type: "bar",
            },
          ],
        };
        window.addEventListener("resize", () => {
          this.analyzeBarChart.resize();
        });
        this.analyzeBarChart && this.analyzeBarChart.setOption(option);
      });
    },

    handleIconClick(icon) {
      this.activeIcon = icon;
    },
    prepareChartData() {
      this.compDataset = [];

      if (this.baseData && this.baseData.headers) {
        // 주 트랜드 데이터셋 처리 (baseData)
        this.baseDataName = this.baseData.headers.text;
        this.baseDataset = this.baseData.results.map(
          (item) => item[this.dispBaseIdx] ?? 0
        );
        this.xAxisLabel = this.baseData.results.map((item) => {
          let val =
            this.queryType == "MONTH"
              ? moment(item.regDt).format("MM-DD")
              : moment(item.regDt).format("MM-DD HH:mm");
          return val;
        });
        let nodeUnit =
          this.$store.state.units.find(
            (unit) => unit.value == this.baseData.headers.sumUnit
          ).text ?? "kWh";
        this.unitMap[this.baseDataName] = nodeUnit;

        // 비교 트랜드 데이터셋 처리 (compData)
        if (
          !this.isEmpty(this.compData) &&
          !this.isEmpty(this.compData.headers)
        ) {
          let found = this.compData.headers.find(
            (item) => item.ptIdx == this.dispCompIdx
          );
          if (!this.isEmpty(found)) {
            this.compDataName = found.ptName;
            let rldUnit = this.$store.state.units.find(
              (unit) => unit.value == found.unitSmallCode
            ).text;
            this.unitMap[this.compDataName] = rldUnit;
          }
          this.compDataset = this.compData.results.map(
            (item) => item[this.dispCompIdx] ?? 0
          );
        } else this.compDataset = [];

        if (!this.isEmpty(this.predictData)) {
          this.predictDataSet = this.predictData;
          this.predictDataName = this.predictTitle;
          this.unitMap[this.predictDataName] = nodeUnit;
        } else {
          this.predictDataSet = [];
          this.predictDataName = "";
        }

        if (!this.isEmpty(this.baseData.statsDesc)) {
          this.statsDesc = this.baseData.statsDesc;
        } else this.statsDesc = null;

        this.renderChart();
      }
    },

    renderChart() {
      const chart = echarts.init(this.$refs.lineChart);
      let unitMap = this.unitMap;

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (params) {
            // params 배열로 전달되어 첫 번째 데이터와 두 번째 데이터 값을 표시
            let result = `<div style="font-size: 12px; width: 230px;">
                            <div class="mb-3">
                                <p class="m-0 p-0">${params[0].axisValue}</p>`;

            // 데이터에 따라 내용 생성
            params.forEach((param) => {
              result += `<div style="display: flex; justify-content: space-between;">
                                <div>
                                    ${param.marker}
                                    <span>${param.seriesName}</span>
                                </div>
                                <span style="font-weight: bold;">${formatNumber(
                                  param.data
                                )} ${
                unitMap[param.seriesName] ? unitMap[param.seriesName] : ""
              }</span>
                            </div>`;
            });

            result += `</div></div>`;
            return result;
          },
        },
        legend: {
          data: [this.baseDataName, this.compDataName],
          left: 10,
          show: false,
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: "all",
            },
          ],
        },
        grid: [
          {
            left: 70,
            right: 70,
            height: "45%",
          },
          {
            left: 70,
            right: 70,
            top: "70%",
            height: "29%",
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: true },
            data: this.xAxisLabel,
            axisLabel: {
              align: "left",
            },
          },
          {
            show: false,
            gridIndex: 1,
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: true },
            data: this.xAxisLabel,
            position: "top",
            axisLabel: {
              align: "left",
            },
          },
        ],
        yAxis: [
          {
            name: `${this.baseDataName} (${this.unitMap[this.baseDataName]})`,
            type: "value",
            min: 0,
            axisLabel: {
              align: "right",
              padding: [0, 10, 0, 0],
              formatter: `{value} ${this.unitMap[this.baseDataName]}`,
            },
            axisPointer: {
              label: {
                formatter: function (value) {
                  return formatNumber(value.value);
                },
              },
            },
          },
          {
            name: `${this.compDataName} (${this.unitMap[this.compDataName]})`,
            gridIndex: 1,
            type: "value",

            min: 0,
            inverse: true,

            axisLabel: {
              align: "right",
              padding: [0, 10, 0, 0],
              formatter: `{value} ${this.unitMap[this.compDataName]}`,
            },
            axisPointer: {
              label: {
                formatter: function (value) {
                  return formatNumber(value.value);
                },
              },
            },
          },
        ],
        series: [
          {
            name: `${this.predictDataName}`,
            type: "line",
            smooth: 0.4,
            symbolSize: 3,
            data: this.predictData,
            lineStyle: {
              type: "dashed",
              color: "#64B5F6",
              width: 1,
            },
            itemStyle: {
              color: "#64B5F6",
            },
          },
          {
            name: `${this.baseDataName}`,
            type: "line",
            smooth: 0.4,
            symbolSize: 3,
            data: this.baseDataset,
            lineStyle: {
              color: primaryColor,
              width: 2,
            },
            itemStyle: {
              color: primaryColor,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: primaryColor },
                { offset: 1, color: primaryColor + "00" },
              ]),
            },
          },
          {
            name: `${this.compDataName}`,
            type: "line",
            smooth: 0.4,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: this.compDataset,
            lineStyle: {
              color: secondaryColor,
              width: 2,
            },

            itemStyle: {
              color: secondaryColor,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: secondaryColor + "00" },
                { offset: 1, color: secondaryColor },
              ]),
            },
          },
        ],
      };

      chart.setOption(option);
      window.addEventListener("resize", () => {
        chart.resize();
      });
    },
  },
};
</script>

<style scoped>
.chart {
  width: 300px;
  height: 300px;
}

.externalStateMenu {
  display: flex;
  justify-content: flex-end;
}

.status-icon {
  color: #555;
  background-color: #fff;
  border: solid #bbb 1px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2rem;
  transition: background-color 0.5s;
  width: 2rem;
  height: 2rem;
}

.status-icon:hover {
  background-color: #c7c7c7;
}

.status-icon.active {
  color: #fff;
  background-color: #555;
}

.table-container {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  margin-top: 5px;
}

.table-small {
  font-size: 0.75rem;
  /* 폰트 크기 조정 */
}

.b-table td,
.b-table th {
  padding: 0.5rem !important;
  /* 셀의 패딩 조정 */
}

.status-icon:focus,
.status-icon:active {
  outline: none;
  box-shadow: none;
}

.weird-table-container {
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  margin-top: 5px;
}
</style>
